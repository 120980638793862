// colors
$back-color: #37236a;
$body-text-color: #4a494b;
$green-stripe-color: rgba(157, 216, 128, 0.3);
$blue-stripe-color: #ddeef7;
$content-background-color: #f3f4f6;
$caption-color: #37256c;
$text-color: #3e6d8d;
$text-color-brown: $body-text-color;
$gray-text-color: $body-text-color;
$dark-gray-text-color: $text-color-brown;
$light-gray-text-color: #888888;
$marker-color: #93d3f5;
$blue: #3daef2;
$medium-blue: #1a80bb;
$link-color: #00aeef;
$link-color-v2: #0f80b0;
$error-text-color: red;
$coupon-error-color: #ff4646;
$coupon-green-color: #65af14;
$coupon-dark-gray-color: #2d2d2d;
$yellow-background-color: #fefacf;
$text-color-V2: #262837;
$info-color: #7b8291;
$page-background-color-V2: #edeff3;
$field-border-color-V2: #bac3ce;
$light-indigo-V2: #5B43BD;
$white-bg-color-v2: #ffffff;
$black-bg-color-v2: #000000;
$required-red-color: #e74c3c;
$error-field-bg-color: rgba(254, 145, 137, 0.5);
$error-text-color-v2: #fe5c50;
$error-input-error-v2: #ff0000;
$radio-btn-color-v2: #332A85;
$hover-border-color-v2: #70cbf1;
$focus-border-color-v2: #40b9ec;
$body-text-color-v2: #7b8291;
$gray-text-color-v2: $body-text-color;
$light-gray-text-color-v2: #525968;

//New purple themes
$purple-100: #F5F3FB;
$purple-200: #D0C9EC;
$purple-300: #A89CDD;
$purple-400: #816ECC;
$purple-500: #5B43BD;
$purple-600: #43349C;
$purple-700: #332A85;

// landing colors
$landing-text-color: #6b6b6b;
$landing-violet-color: #3f368c;
$landing-blue-color: #00aeef;
$landing-green-color: #8dc63f;
$landing-background-blue-color: #fafafa;
$landing-light-gray-color: #9f9f9f;
// fontss
$awesome: 'FontAwesome', serif;
$roboto: 'Roboto', Arial, Helvetica, sans-serif;
$sans: 'Open Sans', 'Roboto', sans-serif;
$rionasans-regular: 'RionaSans', Arial, Helvetica, sans-serif;
$rionasans-medium: 'RionaSans-Medium', Arial, Helvetica, sans-serif;
$rionasans-bold: 'RionaSans-Bold', Arial, Helvetica, sans-serif;
$rionasans-italic: 'RionaSans-Italic', Arial, Helvetica, sans-serif;
$rionasans-light: 'RionaSans-Light', Arial, Helvetica, sans-serif;

// media
$media-retina: '(min--moz-device-pixel-ratio: 1.3),(-o-min-device-pixel-ratio: 2.6/2),(-webkit-min-device-pixel-ratio: 1.3),(min-device-pixel-ratio: 1.3),(min-resolution: 1.3dppx)';
$media-header-visible: '(min-width: 970px)';
    $header-md: '(min-width: 500px)';
    $media-services-page: '(min-width: 1100px)';
    $media-our-process-stripes: '(min-width: 768px)'; // when stripes apear
    $media-landing-bottom-stripes: '(min-width: 768px)'; // when stripes apear
    $media-xxs-mobile: '(min-width: 350px)';
    // sizes
    $header-height: 106px;
    $calendar-width: 900px;
    //others
    $back-border: 1px solid rgba(256, 256, 256, 0.5);
    // forms
    $form-element-height: 40px;
    $form-element-border: 1px solid #dcdcdc;
    $form-element-border-radius: 3px;
    // IE 11 only
    $ie_only: '(-ms-high-contrast: active), (-ms-high-contrast: none)';
    // media min-width
    $la_xl: '(min-width: 1100px)';
    $la_lg: '(min-width: 1024px)';
    $la_md: '(min-width: 768px)';
    $la_sm: '(min-width: 667px)';
    $la_xsm: '(min-width: 375px)';
    $md: '(min-width: 500px)';
    $mobile: 'only screen and (max-device-width: 480px)';

    //SCP colors
    $scp-smoke-color: #a0abb9;

    $label_font: normal normal 12px/1.42 $rionasans-regular;
    $scp-form-element-border: solid 1px #bac3ce;
    $scp-form-element-height: 34px;
    $scp-form-element-border-radius: 4px;
    $scp-form-element-color: $text-color-V2;
    $scp-form-element-font: normal normal 13px/1.38 $rionasans-regular;
    $scp-form-element-placeholder-color: $field-border-color-V2;
    $scp-title1-font: normal bold 22px/1.45 $rionasans-bold;
    $scp-title-font-V3: normal normal 23px/27.6px $rionasans-bold;
    $scp-submit-button-font: normal 500 13px/1.38 $rionasans-medium;
    $scp-submit-button-font-V3: normal 19px/22px $rionasans-medium;
    $scp-border-color: #d8dde4;

    $scp-review-page-label-font: normal normal 12px/1.33 $rionasans-regular;
    $scp-review-page-label-font-V3: normal 16px/20px $rionasans-medium;
    $scp-review-page-value-font: normal normal 14px/1.36 $rionasans-regular;
    $scp-review-page-value-font-V3: normal 16px/20px $rionasans-medium;
    $scp-menu-item: normal normal 14px/1.43 $rionasans-regular;
    $scp-review-page-price-font: normal normal 18px/22px $rionasans-bold;
    $scp-insurance-star-font: normal bold 14px/1.43 $rionasans-bold;
    $scp-calendar-header-light-color: #a0abb9;
    $scp-calendar-table-border-color: #d8dde4;
    $scp-calendar-legend-color: #525968;
    $scp-button-hover-color: #816ECC;
    $scp-button-focus-color: #43349C;
    $scp-progress-green: #98edbb;
    $scp-button-disabled-color: #d1baed;
    $scp-info-box-success-color: #00d65b;
    $scp-info-box-warning-color: #f1c40f;
    $scp-info-box-failure-color: #e74c3c;
    $header2-font: normal bold 32px/1.44 $rionasans-bold;
    $header3-font: normal bold 28px/1.43 $rionasans-bold;
    $header4-font: normal bold 24px/1.42 $rionasans-bold;
    $header6-font: normal bold 18px/1.39 $rionasans-bold;
    $mob-header2-font: normal bold 12px/1.42 $rionasans-bold;
    $mob-header4-font: normal bold 9px/1.39 $rionasans-bold;
    $mob-header6-font: normal normal 8px/1.38 $rionasans-regular;
    $mob-sub-header-font : normal normal 7px/1.43 $rionasans-regular;
    $mob-sub-header-bold-font : normal bold 7px/1.43 $rionasans-bold;

    $scp-link-button-font: normal 500 14px/1.38 $rionasans-medium;
    $mob-input-field-text : normal 500 7px/1.43 $rionasans-medium;